<template>
  <v-row>
    <v-snackbar
      v-if="snackbar"
      v-model="snackbarTop"
      absolute
      top
      :color="snackbarColor"
    >
      {{ snackbarTop }}
      <v-btn color="white" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
    <v-col cols="12">
      <v-data-table
        :loading="getLoading"
        :headers="headers"
        :items="getFilteredItems"
        item-key="id"
        fixed-header
        :options.sync="options"
        :server-items-length="getLogCount"
        :items-per-page="getInfoItemsPerPage"
        sortable="false"
        height="calc(100vh - 250px)"
        :footer-props="{
          'show-first-last-page': true,
          'items-per-page-options': [10, 20, 30, 50, 100],
        }"
      >
        <template v-slot:top>
          <v-row><v-divider /></v-row>
          <v-row align="center" justify="space-around">
            <v-col cols="4">
              <v-row class="align-center">
                <v-col cols="10">
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="datePickerDateRange"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        label="Date range"
                      />
                    </template>
                    <v-date-picker
                      v-model="datePickerDate"
                      range
                      @input="selectDate(datePickerDate)"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="2">
                  <v-icon
                    :disabled="!datePickerDate.length"
                    color="red darken-3"
                    icon
                    @click="resetDateRange"
                  >
                    mdi-close-circle
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4">
              <v-row class="align-center">
                <v-col cols="10">
                  <v-autocomplete
                    v-model="publishers"
                    :items="defaultPfPublishers"
                    item-text="name"
                    item-value="id"
                    label="Publisher"
                    :search-input.sync="setPublisherNameSearch"
                    :menu-props="{ offsetY: true }"
                    multiple
                    @change="filterItems(publishers, 'setPublisherName')"
                  />
                </v-col>
                <v-col cols="2">
                  <v-icon
                    :disabled="!publishers.length"
                    color="red darken-3"
                    icon
                    @click="resetFiltering('setPublisherName', 'publishers')"
                  >
                    mdi-close-circle
                  </v-icon>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.calculator_version="{ item }">
          <span>{{
            item.publisher_calculator_version !== ""
              ? item.calculator_version
              : "Default (&nbsp;" + item.calculator_version + "&nbsp;)"
          }}</span>
        </template>
        <template v-slot:item.start_date="{ item }">
          <span>{{ formatDate(item.start_date) }}</span>
        </template>
        <template v-slot:item.end_date="{ item }">
          <span>{{ formatDate(item.end_date) }}</span>
        </template>
        <template v-slot:item.modification_date="{ item }">
          <span>{{ formatDate(item.modification_date) }}</span>
        </template>
        <template v-slot:item.action="{ item }">
          <div style="white-space: nowrap">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn depressed icon v-on="on" @click="editPfPublisher(item)">
                  <v-icon small color="blue">
                    settings
                  </v-icon>
                </v-btn>
              </template>
              <span>Settings</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  icon
                  v-on="on"
                  :href="`/priceFloor/configuration?publisher=${item.name}`"
                  target="_blank"
                >
                  <v-icon small color="blue">
                    folder_copy
                  </v-icon>
                </v-btn>
              </template>
              <span>PFUnit Manager</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  icon
                  v-on="on"
                  :href="
                    item.pf_data_studio_link ? item.pf_data_studio_link : ''
                  "
                  target="_blank"
                >
                  <v-icon
                    small
                    :color="linkColor(item.pf_data_studio_link || false)"
                  >
                    signal_cellular_alt
                  </v-icon>
                </v-btn>
              </template>
              <span>Data Studio</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <PriceFloorPublisherDialog
        ref="pf_publishers_dialog"
        v-bind:publisher-info="publisherInfo"
        v-bind:close-callback="closeEditPublisher"
      />
      <PriceFloorEditPublisherDialog ref="pf_edit_publishers_dialog" />
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { filterMixin } from "@/helpers/filterMixin";
import PriceFloorPublisherDialog from "./PriceFloorPublisherDialog";
import PriceFloorEditPublisherDialog from "./PriceFloorEditPublisherDialog";

export default {
  name: "PriceFloorPublishers",
  components: {
    PriceFloorPublisherDialog,
    PriceFloorEditPublisherDialog,
  },
  mixins: [filterMixin],
  data() {
    return {
      options: {},
      readOnly: false,
      headers: [
        {
          text: "Publisher",
          value: "name",
          sortable: false,
        },
        {
          text: "Start",
          value: "start_date",
          sortable: false,
        },
        {
          text: "End",
          value: "end_date",
          sortable: false,
        },
        {
          text: "Last modification",
          value: "modification_date",
          sortable: false,
        },
        {
          text: "Version",
          value: "calculator_version",
          sortable: false,
        },
        {
          text: "Enabled pfunit auto",
          value: "enabled_pfunit_auto",
          sortable: false,
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          align: "center",
        },
      ],
      datePicker: false,
      datePickerDate: [],
      publishers: [],
      setPublisherNameSearch: null,
      editedIndex: -1,
      editedItem: {},
      publisherInfo: {},
      snackbar: false,
      snackbarTop: "",
      snackbarColor: "warning",
      defaultPfPublishers: [],
    };
  },
  watch: {
    options: {
      handler() {
        this.updatePaginationInfo({
          page: this.options.page,
          itemsPerPage: this.options.itemsPerPage,
        });
      },
      deep: true,
    },
    getFilteredItems(newVal) {
      if (newVal && this.defaultPfPublishers.length === 0) {
        this.defaultPfPublishers = this.getPfPublishers;
      }
    },
  },
  mounted() {
    this.setSearchData([]);
    this.setPublisherName([]);
    this.fetchPfPublishers();
  },
  computed: {
    getFilteredItems() {
      return this.getPfPublishers;
    },
    datePickerDateRange() {
      return this.datePickerDate.join(" - ");
    },
    ...mapGetters("publishers", ["getGAMNetworkName"]),
    ...mapGetters("priceFloorPublishers", [
      "getLoading",
      "getPfPublishers",
      "getInfoItemsPerPage",
      "getLogCount",
      "getStats",
      "getPublisherCurrency",
      "getAllCountries",
      "getPublisherData",
    ]),
  },
  methods: {
    ...mapActions("priceFloorPublishers", [
      "setSearchDataAction",
      "updatePaginationInfo",
      "fetchFilter",
      "fetchPfPublishers",
      "fetchCurrencyForPublisher",
      "fetchCurrencyDashboardForPublisher",
      "fetchDataForPublisher",
      "fetchPublisherCountries",
      "fetchAllCountries",
    ]),
    ...mapMutations("priceFloorPublishers", [
      "setPublisherName",
      "setSearchData",
    ]),
    selectDate() {
      this.snackbar = false;
      if (
        this.datePickerDate.length === 2 &&
        this.datePickerDate[0] <= this.datePickerDate[1]
      ) {
        this.datePicker = false;
        this.options.page = 1;
        this.setSearchDataAction(this.datePickerDate);
      } else if (
        this.datePickerDate.length === 2 &&
        this.datePickerDate[0] > this.datePickerDate[1]
      ) {
        this.snackbarTop = "The end date can't be earlier than the start date";
        this.snackbarColor = "error";
        this.snackbar = true;
      } else {
        this.snackbarTop = "Please select the range of dates";
        this.snackbarColor = "blue";
        this.snackbar = true;
      }
    },
    resetDateRange() {
      this.datePickerDate = [];
      this.options.page = 1;
      this.setSearchDataAction([]);
    },
    filterItems(item, name) {
      this[`${name}Search`] = null;
      this.options.page = 1;
      this.fetchFilter({ name, data: item });
    },
    resetFiltering(name, filter) {
      this[`${filter}`] = [];
      this.options.page = 1;
      this.fetchFilter({ name, data: [] });
    },
    linkColor(param = false) {
      if (param) return "blue";
      else return "grey";
    },
    editPfPublisher(item) {
      this.editedIndex = this.getPfPublishers.indexOf(item);
      this.publisherInfo = { ...item };
      if (this.publisherInfo.publisher_calculator_version === "") {
        this.publisherInfo.calculator_version = null;
      }
      this.fetchCurrencyForPublisher(item.network_id);
      this.fetchCurrencyDashboardForPublisher(item.name);
      this.fetchPublisherCountries(item.name);
      this.getAllCountries.length = 0;
      this.fetchAllCountries();
      this.$refs.pf_publishers_dialog.readOnly = false;
      this.$refs.pf_publishers_dialog.show = true;
    },
    closeEditPublisher() {
      this.$refs.pf_publishers_dialog.show = false;
      this.publisherInfo = {};
    },
    formatDate(item) {
      if (item) {
        return `${item.split("T")[0]} ${item.split("T")[1]}`;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style scoped></style>
