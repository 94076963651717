<template>
  <v-dialog
    v-model="show"
    @click:outside="close"
    @keydown.esc="close"
    persistent
    max-width="80%"
  >
    <v-card style="overflow-x: hidden;">
      <v-row align="center">
        <v-col cols="auto">
          <v-card-title>
            <span class="headline">Opti Yield settings:</span>
          </v-card-title>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            v-if="!readOnly"
            :disabled="!valid || loadingCountries"
            color="blue darken-1"
            text
            @click="save"
          >
            {{ $t("buttons.general.save") }}
          </v-btn>
          <v-btn color="blue darken-1" text @click="close">
            {{ $t("buttons.general.close") }}</v-btn
          >
        </v-col>
      </v-row>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation class="d-flex">
            <v-container style="width: 40%">
              <v-row no-gutters>
                <v-row style="width: 100%;">
                  <v-col cols="12">
                    <v-card-title>
                      Version:
                    </v-card-title>
                  </v-col>
                  <v-col cols="10">
                    <v-autocomplete
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getCalculatorVersions"
                      item-text="code"
                      item-value="id"
                      label="Version"
                      :value="publisherInfo.publisher_calculator_version"
                      @change="updateVersion"
                      return-object
                    />
                  </v-col>
                </v-row>
                <v-row style="width: 100%;">
                  <v-col cols="12">
                    <v-card-title>
                      General:
                    </v-card-title>
                  </v-col>
                  <v-col cols="10">
                    <v-text-field
                      :readonly="readOnly"
                      :filled="readOnly"
                      v-model="publisherInfo.pf_data_studio_link"
                      label="Data Studio Link Opti Yield"
                    />
                  </v-col>
                </v-row>
                <v-row style="width: 100%;">
                  <v-col cols="12">
                    <v-card-title>
                      Enabled pfunit auto:
                    </v-card-title>
                  </v-col>
                  <v-col cols="10">
                    <v-switch
                      :input-value="getEnabledPfUnitAuto"
                      @change="updateEnabledPfUnitAuto"
                    >
                      <template v-slot:label>
                        <span class="v-switch-label-color"
                          >Warning: If you enable Pfunit-auto all pfunits of
                          this publisher will be enabled</span
                        >
                      </template>
                    </v-switch>
                  </v-col>
                </v-row>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-title>
                    Currency management:
                  </v-card-title>
                </v-col>
                <v-row style="width: 100%;">
                  <v-col cols="10">
                    <v-autocomplete
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getAllCurrencies"
                      item-text="name"
                      item-value="id"
                      label="Currency used in the GAM"
                      v-model="getPublisherCurrency.gam_currency"
                      @change="
                        item => {
                          this.getPublisherCurrency.gam_currency_id = item.id;
                        }
                      "
                      return-object
                    />
                  </v-col>
                </v-row>
                <v-row style="width: 100%;">
                  <v-col cols="10">
                    <v-autocomplete
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getAllCurrencies"
                      item-text="name"
                      item-value="id"
                      label="Currency used in Prebid"
                      v-model="getPublisherCurrency.prebid_currency"
                      @change="
                        item => {
                          this.getPublisherCurrency.prebid_currency_id =
                            item.id;
                        }
                      "
                      return-object
                    />
                  </v-col>
                </v-row>
                <v-row style="width: 100%;">
                  <v-col cols="10">
                    <v-autocomplete
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getAllCurrencies"
                      item-text="code"
                      item-value="code"
                      label="Currency Dashboard"
                      v-model="getPublisherCurrencyDashboard"
                      @change="
                        item => {
                          this.setPublisherCurrencyDashboard(item.code);
                        }
                      "
                      return-object
                    />
                  </v-col>
                </v-row>
                <v-row style="width: 100%;">
                  <v-col cols="10">
                    <v-text-field
                      value="USD"
                      label="Currency used in Amazon"
                      disabled
                    />
                  </v-col>
                </v-row>
                <v-row style="width: 100%;">
                  <v-col cols="10">
                    <v-text-field
                      value="EUR"
                      label="Currency used in reporting"
                      disabled
                    />
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
            <LoadingOverlay :overlay="loadingCountries">
              <template v-slot:default>
                <v-container style="width: 75%; height: auto">
                  <v-row style="width: 100%;">
                    <v-col cols="12">
                      <v-card-title>
                        Country management:
                      </v-card-title>
                    </v-col>

                    <v-row
                      class="rounded countryContainerBorders"
                      style="width: 100%;"
                    >
                      <v-col
                        cols="6"
                        class="pa-0 countryContainerBorderRight"
                        style="width: 100%;"
                      >
                        <v-container
                          class="countryContainerBorderBottom countryContainerRowHeight"
                          style="width: 100%;"
                        >
                          <v-text-field
                            flat
                            solo
                            type="text"
                            class="ma-0 pa-0"
                            hide-details
                            append-icon="mdi-magnify"
                            label="Search"
                            v-model="search"
                          />
                        </v-container>
                        <v-container
                          class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                          style="width: 100%;"
                        >
                          <v-card-title
                            v-if="
                              getAllCountries.length != 0 && !loadingCountries
                            "
                            class="font-weight-bold text-body-2"
                            >{{ searchCountry.length }} items</v-card-title
                          >
                          <v-card-title
                            v-else
                            class="font-weight-bold text-body-2"
                          >
                            No items
                          </v-card-title>
                        </v-container>
                        <v-container
                          class="pt-1 pb-1 overflow-x-hidden"
                          style="height: 376px;"
                        >
                          <v-list
                            flat
                            v-if="
                              getAllCountries.length != 0 && !loadingCountries
                            "
                          >
                            <v-list-item-group>
                              <v-list-item
                                v-for="item in searchCountry"
                                v-bind:key="item.id"
                                v-bind:title="item.name"
                                class="pb-0 text-body-2 font-weight-regular"
                                @click="addCountry(item)"
                              >
                                {{ capitalize(item.name) }}
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-container>
                      </v-col>

                      <v-col cols="6" class="pa-0" style=" width: 100%;">
                        <v-container
                          class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                          style="width: 100%;"
                        >
                          <v-row>
                            <v-col cols="6">
                              <v-card-title
                                v-if="
                                  getPublisherCountries &&
                                    getPublisherCountries.countries &&
                                    getPublisherCountries.countries.length >
                                      0 &&
                                    !loadingCountries
                                "
                                class="font-weight-bold text-body-2"
                              >
                                {{ getPublisherCountries.countries.length }}
                                items
                              </v-card-title>
                              <v-card-title
                                v-else
                                class="font-weight-bold text-body-2"
                                >None selected</v-card-title
                              >
                            </v-col>
                            <v-col cols="6" class="pt-5" align="right">
                              <v-btn
                                v-model="setAllCountries"
                                class="text-capitalize"
                                color="black"
                                plain
                                @click="deleteAllCountries"
                              >
                                Clear All
                              </v-btn>
                            </v-col>
                          </v-row>
                        </v-container>
                        <v-container
                          class="pt-1 pb-1 overflow-x-hidden"
                          style="height: 442px;"
                        >
                          <v-list class="pt-1 pb-1" flat>
                            <v-list-item-group
                              v-for="(item, index) in orderPublisherCountries"
                              v-bind:key="item.name"
                            >
                              <v-list-item
                                v-if="
                                  getPublisherCountries &&
                                    getPublisherCountries.countries &&
                                    getPublisherCountries.countries.length >
                                      0 &&
                                    !loadingCountries
                                "
                                v-bind:key="index"
                                v-bind:title="item.name"
                                class="pb-0 text-body-2 font-weight-regular"
                                @click="removeCountry(index, item)"
                                return
                                obj
                              >
                                {{ capitalize(item.name) }}
                              </v-list-item>
                              <v-list-item
                                v-else-if="
                                  !getPublisherCountries.countries.length
                                "
                                class="pa-2 text-body-1 font-weight-regular"
                              >
                                No countries selected.
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-container>
                      </v-col>
                    </v-row>
                  </v-row>
                </v-container>
              </template>
            </LoadingOverlay>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!readOnly"
          :disabled="!valid || loadingCountries"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t("buttons.general.save") }}</v-btn
        ><v-btn color="blue darken-1" text @click="close">{{
          $t("buttons.general.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { baseValidation } from "../../../validation/baseValidation";
import { validationHelper } from "../../../validation/validationHelper";
import { publisherValidation } from "../../../validation/publisherValidation";
import LoadingOverlay from "@/components/Helpers/LoadingOverlay";

export default {
  name: "PriceFloorPublisherDialog",
  props: ["publisherInfo", "closeCallback"],
  components: { LoadingOverlay },
  mixins: [baseValidation, validationHelper, publisherValidation],
  data() {
    return {
      options: {},
      show: false,
      readOnly: false,
      valid: true,
      search: "",
      loadingCountries: true,
    };
  },
  watch: {
    getAllCountries(newVal) {
      this.setAllCountries = newVal;
    },
    getPublisherCountries(newVal) {
      this.countriesList();
      this.setPublisherCountries = newVal;
    },
  },
  mounted() {
    this.fetchCalculatorVersions();
    this.fetchAllCurrencies();
  },
  computed: {
    searchCountry() {
      this.orderCountries(this.getAllCountries);
      return this.getAllCountries.filter(country => {
        return country.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    getEnabledPfUnitAuto() {
      if (typeof this.publisherInfo.enabled_pfunit_auto === "boolean") {
        return this.publisherInfo.enabled_pfunit_auto;
      } else {
        return false;
      }
    },
    orderPublisherCountries() {
      if (this.getPublisherCountries.countries) {
        this.orderCountries(this.getPublisherCountries.countries);
      }
      return this.getPublisherCountries.countries;
    },
    ...mapGetters("priceFloorPublishers", [
      "getCalculatorVersions",
      "getAllCurrencies",
      "getPublisherCurrency",
      "getAllCountries",
      "getPublisherCountries",
      "getLoading",
      "getPublisherCurrencyDashboard",
    ]),
  },
  methods: {
    ...mapActions("priceFloorPublishers", [
      "updatePublisher",
      "createPublisher",
      "fetchCalculatorVersions",
      "fetchAllCurrencies",
      "updatePublisherCurrency",
      "updatePublisherCurrencyDashboard",
      "updatePublisherCountries",
    ]),
    ...mapMutations("priceFloorPublishers", [
      "setAllCountries",
      "setPublisherCountries",
      "setPublisherCurrencyDashboard",
    ]),
    close() {
      this.closeCallback();
      this.search = "";
    },
    async save() {
      if (this.$refs.form.validate()) {
        const currencyObj = {
          gam_currency_id: this.getPublisherCurrency.gam_currency_id,
          prebid_currency_id: this.getPublisherCurrency.prebid_currency_id,
          network_id: this.publisherInfo.network_id,
        };

        const countriesList = [];

        for (let i = 0; i < this.getPublisherCountries.countries.length; i++) {
          let objElement = {
            gam_id: this.getPublisherCountries.countries[i].gam_id,
          };
          countriesList.push(objElement);
        }
        const publisher_countries = {
          network_id: this.publisherInfo.network_id,
          publisher_code: this.publisherInfo.name,
          countries: countriesList,
        };

        this.updatePublisher(this.publisherInfo);
        this.updatePublisherCurrency(currencyObj);
        this.updatePublisherCurrencyDashboard({
          pub_name: this.publisherInfo.name,
          currency: this.getPublisherCurrencyDashboard,
        });
        await this.updatePublisherCountries(publisher_countries);
        this.close();
      }
    },
    async countriesList() {
      this.loadingCountries = true;

      if (
        this.getAllCountries &&
        this.getPublisherCountries.countries &&
        this.getAllCountries.length > 0 &&
        this.getPublisherCountries.countries.length > 0 &&
        this.getAllCountries.length !==
          this.getPublisherCountries.countries.length
      ) {
        for (let i = this.getAllCountries.length - 1; i >= 0; i--) {
          if (this.getAllCountries !== undefined) {
            for (
              let k = 0;
              k < this.getPublisherCountries.countries.length;
              k++
            ) {
              if (
                this.getAllCountries[i] !== undefined &&
                this.getAllCountries[i].gam_id ===
                  this.getPublisherCountries.countries[k].gam_id
              ) {
                await this.getAllCountries.splice(i, 1);
                this.loadingCountries = false;
              }
            }
          }
        }
      }

      if (
        this.getPublisherCountries.countries.length > 0 &&
        this.getAllCountries.length ===
          this.getPublisherCountries.countries.length
      ) {
        this.getAllCountries.length = 0;
        this.loadingCountries = false;
      }

      if (this.getPublisherCountries.publisher_code === null) {
        return;
      }

      this.loadingCountries = false;
    },
    async addCountry(item) {
      let objWithIdIndex = this.getAllCountries.findIndex(
        obj => obj.gam_id === item.gam_id
      );
      let newCountry = {
        name: this.formatCountryName(item.name),
        gam_id: item.gam_id,
      };

      await this.getPublisherCountries.countries.push(newCountry);
      await this.getAllCountries.splice(objWithIdIndex, 1);
      this.search = "";
    },
    async removeCountry(index, item) {
      this.getPublisherCountries.countries.splice(index, 1);
      let newCountry = {
        name: item.name.toLowerCase(),
        gam_id: item.gam_id,
      };
      await this.getAllCountries.push(newCountry);
    },
    async deleteAllCountries() {
      await this.getPublisherCountries.countries.forEach(country => {
        this.getAllCountries.push(country);
      });
      this.getPublisherCountries.countries = [];
      await this.getAllCountries;
    },
    updateVersion(item) {
      if (item.id !== "") {
        this.publisherInfo.calculator_version = item.id;
      } else {
        this.publisherInfo.calculator_version = null;
      }
    },
    updateEnabledPfUnitAuto() {
      if (typeof this.publisherInfo.enabled_pfunit_auto === "boolean") {
        this.publisherInfo.enabled_pfunit_auto = !this.publisherInfo
          .enabled_pfunit_auto;
      }
    },
    formatCountryName(name) {
      return name[0].toUpperCase() + name.slice(1).toLowerCase();
    },
    orderCountries(countries_list) {
      return countries_list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },
    capitalize(countryName) {
      return countryName.toUpperCase();
    },
  },
};
</script>

<style>
.countryContainerBorders {
  border: 1px solid #bcbcbc;
}
.countryContainerBorderRight {
  border-right: 1px solid #bcbcbc;
}
.countryContainerBorderBottom {
  border-bottom: 1px solid #bcbcbc;
}
.countryContainerRowHeight {
  max-height: 65px;
}
.v-switch-label-color {
  color: red;
}
</style>
