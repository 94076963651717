<template>
  <v-dialog v-model="show" max-width="80%">
    <v-card style="overflow-x: hidden;">
      <v-row align="center">
        <v-col cols="auto">
          <v-card-title>
            <span v-if="!readOnly && editedIndex !== -1" class="headline"
              >{{ $t("buttons.publishers.edit") }}:</span
            >
            <span v-else-if="readOnly && editedIndex !== -1" class="headline"
              >{{ $t("buttons.publishers.show") }}:</span
            >
            <span v-else class="headline"
              >{{ $t("buttons.publishers.add") }}:</span
            >
          </v-card-title>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            v-if="!readOnly"
            :disabled="!valid || loadingCountries"
            color="blue darken-1"
            text
            @click="save"
          >
            {{ $t("buttons.general.save") }}</v-btn
          ><v-btn color="blue darken-1" text @click="close">{{
            $t("buttons.general.close")
          }}</v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-title>{{ $t("publishers.general") }}:</v-card-title>
                </v-col>
                <v-col cols="12">
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="editedItem.start_date"
                        prepend-icon="event"
                        readonly
                        :label="$t('general.start_date')"
                        v-on="on"
                        :rules="createRules([baseValidation.required])"
                      />
                    </template>
                    <v-date-picker
                      v-model="editedItem.start_date"
                      type="date"
                      @input="selectDate(editedItem.start_date)"
                    />
                  </v-menu>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.name"
                    label="Name"
                    :rules="createRules([baseValidation.required])"
                  />
                  <v-autocomplete
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getInvoiceTypes"
                    item-text="name"
                    item-value="id"
                    :label="$t('publishers.select_invoice_type')"
                    v-model="editedItem.invoice_type_id"
                    :rules="createRules([baseValidation.required])"
                  />
                  <v-autocomplete
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getCurrencies"
                    item-text="code"
                    item-value="id"
                    :label="$t('publishers.select_currency')"
                    v-model="editedItem.currency_id"
                    :rules="createRules([baseValidation.required])"
                  />
                  <v-autocomplete
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getSellerTypes"
                    label="Seller type"
                    v-model="editedItem.seller_type"
                    :rules="createRules([baseValidation.non_empty_array])"
                  />
                </v-col>

                <v-col cols="3">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model.number="editedItem.gam_network_id"
                    label="GAM Network ID"
                    :rules="createRules([baseValidation.decimal])"
                  />
                  <v-text-field
                    readonly
                    :filled="readOnly"
                    v-model="gam_network_name"
                    label="GAM Network Name"
                    :loading="getGAMNetworkFetchLoading"
                  />
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.data_studio_url"
                    :rules="createRules([baseValidation.url_with_http])"
                    label="Data Studio Link"
                  />
                  <v-select
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.default_schain"
                    :items="[
                      {
                        t: 'Opti digital',
                        v: 'optidigital',
                      },
                      {
                        t: 'Direct',
                        v: 'direct',
                      },
                    ]"
                    item-text="t"
                    item-value="v"
                    dense
                  >
                    <template v-slot:label>
                      <IconTooltip
                        :content="$t('web_setting.label.schain')"
                        :description="$t('web_setting.help.schain')"
                      />
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    readonly
                    :filled="readOnly"
                    v-model="editedItem.odas_account_name"
                    label="ODAS Account Name"
                  />
                  <v-text-field
                    readonly
                    :filled="readOnly"
                    v-model="editedItem.odas_account_id"
                    label="ODAS Account ID"
                  />
                  <v-autocomplete
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getProducts"
                    item-text="name"
                    item-value="id"
                    label="Product"
                    v-model="editedItem.products"
                    return-object
                    multiple
                    :rules="
                      createRules([
                        baseValidation.non_empty_array,
                        baseValidation.checkProductSelection,
                      ])
                    "
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.url"
                    label="URL"
                    :rules="createRules([baseValidation.domain])"
                  />
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.ownerdomain"
                    label="OWNERDOMAIN"
                    :rules="createRules([baseValidation.domain])"
                  />
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.seller_domain"
                    label="Seller Domain"
                    :rules="[
                      ...baseValidation.domain,
                      baseValidation.requiredConditional(
                        editedItem.mcm_manage_inventory,
                        editedItem.seller_domain
                      ),
                    ]"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.limited_ads"
                    label="Use Limited Ads"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.active"
                    label="Publisher reports"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.mcm_manage_inventory"
                    label="MCM Manage Inventory"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.allowExternConfigure"
                    label="Allow extern to access full site configuration."
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card-title
                    >{{ $t("publishers.company_info") }}:</v-card-title
                  >
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.company_name"
                    label="Company Name"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.company_address"
                    label="Company Address"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.company_country"
                    label="Company Country"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.company_iban"
                    label="Company IBAN"
                    :rules="createRules([publisherValidation.iban])"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.company_bic"
                    label="Company BIC"
                    :rules="createRules([publisherValidation.bic])"
                  />
                </v-col>
                <v-col cols="auto">
                  <v-switch
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.vat_present"
                    label="Use VAT"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.company_vat"
                    label="Company VAT"
                    :rules="[
                      baseValidation.requiredConditional(
                        editedItem.vat_present,
                        editedItem.company_vat
                      ),
                    ]"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.sales_contact_name"
                    label="Sales Contact Name"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model="editedItem.sales_contact_email"
                    label="Sales Contact Email"
                    :rules="createRules([baseValidation.email])"
                  />
                </v-col>
                <v-col cols="5">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    label="Invoice Name"
                    v-model="editedItem.invoicing_contact_name"
                    :rules="createRules([baseValidation.required])"
                  />
                </v-col>
                <v-col cols="12">
                  <div
                    v-for="(contact, i) in invoiceContactsList"
                    :key="i"
                    class="text-fields-row"
                  >
                    <v-row>
                      <v-col cols="5">
                        <v-text-field
                          :readonly="readOnly"
                          :filled="readOnly"
                          label="Invoicing Contact Name"
                          v-model="contact.name"
                          :rules="createRules([baseValidation.required])"
                        />
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          :readonly="readOnly"
                          :filled="readOnly"
                          label="Invoicing Contact Email"
                          v-model="contact.email"
                          :rules="
                            createRules([
                              baseValidation.required,
                              baseValidation.email,
                            ])
                          "
                        />
                      </v-col>
                      <v-col
                        cols="1"
                        class="d-flex align-center justify-center"
                      >
                        <v-col cols="6">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon
                                  color="red"
                                  @click="removeRow(i)"
                                  :disabled="!(invoiceContactsList.length > 1)"
                                  >remove_circle_outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Remove row</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="6">
                          <v-tooltip
                            bottom
                            v-if="
                              invoiceContactsList.length === i + 1 &&
                                contact.name &&
                                contact.email
                            "
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon color="blue" @click="addRow(contact)"
                                  >add_circle_outline
                                </v-icon>
                              </v-btn>
                            </template>
                            <span>Add row</span>
                          </v-tooltip>
                        </v-col>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getAgents"
                    item-text="email"
                    item-value="id"
                    :label="$t('publishers.select_agent')"
                    v-model="editedItem.agent_user_id"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-autocomplete
                    :readonly="readOnly"
                    :filled="readOnly"
                    :items="getAgents"
                    item-text="email"
                    item-value="id"
                    :label="$t('publishers.select_sales_manager')"
                    v-model="editedItem.sales_manager_user_id"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="readOnly"
                    :filled="readOnly"
                    v-model.number="editedItem.share_publisher_percentage"
                    label="Share Publisher Percentage"
                    :rules="
                      createRules([
                        baseValidation.required,
                        baseValidation.percentage_integer,
                      ])
                    "
                    prefix="%"
                  />
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    :readonly="true"
                    :filled="readOnly"
                    v-model="editedItem.traffective_percentage"
                    label="Traffective Percentage"
                    :rules="
                      createRules([
                        baseValidation.required,
                        baseValidation.percentage_integer,
                      ])
                    "
                    prefix="%"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-card-title> Technical :</v-card-title>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                    readonly
                    filled
                    v-model="editedItem.publisher_code"
                    label="Publisher code"
                  />
                </v-col>
              </v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="4">
                    <v-card-title> Pennylane Mapping :</v-card-title>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      :loading="getLoadingPennylane"
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getPennylaneCustomers"
                      :value="getPennylaneMappingPublisher.customer"
                      v-on:change="onCustomerUpdate"
                      item-text="name"
                      item-value="pennylane_source_id"
                      label="Customer"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      :loading="getLoadingPennylane"
                      :readonly="readOnly"
                      :filled="readOnly"
                      :items="getPennylaneSuppliers"
                      :value="getPennylaneMappingPublisher.supplier"
                      v-on:change="onSupplierUpdate"
                      item-text="name"
                      item-value="pennylane_source_id"
                      label="Supplier"
                      :rules="createRules([baseValidation.required])"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-row>
                <LoadingOverlay :overlay="loadingCountries">
                  <template v-slot:default>
                    <v-container style="width: 75%; height: auto">
                      <v-row style="width: 100%;">
                        <v-col cols="12">
                          <v-card-title>
                            Country management:
                          </v-card-title>
                        </v-col>

                        <v-row
                          class="rounded countryContainerBorders"
                          style="width: 100%;"
                        >
                          <v-col
                            cols="6"
                            class="pa-0 countryContainerBorderRight"
                            style="width: 100%;"
                          >
                            <v-container
                              class="countryContainerBorderBottom countryContainerRowHeight"
                              style="width: 100%;"
                            >
                              <v-text-field
                                flat
                                solo
                                type="text"
                                class="ma-0 pa-0"
                                hide-details
                                append-icon="mdi-magnify"
                                label="Search"
                                v-model="search"
                              />
                            </v-container>
                            <v-container
                              class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                              style="width: 100%;"
                            >
                              <v-card-title
                                v-if="
                                  getAllCountries.length !== 0 &&
                                    !loadingCountries
                                "
                                class="font-weight-bold text-body-2"
                                >{{ searchCountry.length }} items</v-card-title
                              >
                              <v-card-title
                                v-else
                                class="font-weight-bold text-body-2"
                              >
                                No items
                              </v-card-title>
                            </v-container>
                            <v-container
                              class="pt-1 pb-1 overflow-x-hidden"
                              style="height: 376px;"
                            >
                              <v-list
                                flat
                                v-if="
                                  getAllCountries.length !== 0 &&
                                    !loadingCountries
                                "
                              >
                                <v-list-item-group>
                                  <v-list-item
                                    v-for="item in searchCountry"
                                    v-bind:key="item.id"
                                    v-bind:title="item.name"
                                    class="pb-0 text-body-2 font-weight-regular"
                                    @click="addCountry(item)"
                                  >
                                    {{ capitalize(item.name) }}
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-container>
                          </v-col>

                          <v-col cols="6" class="pa-0" style=" width: 100%;">
                            <v-container
                              class="pa-2 countryContainerBorderBottom countryContainerRowHeight"
                              style="width: 100%;"
                            >
                              <v-row>
                                <v-col cols="6">
                                  <v-card-title
                                    v-if="
                                      getPublisherCountries?.countries?.length >
                                        0 && !loadingCountries
                                    "
                                    class="font-weight-bold text-body-2"
                                  >
                                    {{ getPublisherCountries.countries.length }}
                                    items
                                  </v-card-title>
                                  <v-card-title
                                    v-else
                                    class="font-weight-bold text-body-2"
                                    >None selected</v-card-title
                                  >
                                </v-col>
                                <v-col cols="6" class="pt-5" align="right">
                                  <v-btn
                                    v-model="setAllCountries"
                                    class="text-capitalize"
                                    color="black"
                                    plain
                                    @click="deleteAllCountries"
                                  >
                                    Clear All
                                  </v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                            <v-container
                              class="pt-1 pb-1 overflow-x-hidden"
                              style="height: 442px;"
                            >
                              <v-list class="pt-1 pb-1" flat>
                                <v-list-item-group
                                  v-for="(item,
                                  index) in orderPublisherCountries"
                                  v-bind:key="item.name"
                                >
                                  <v-list-item
                                    v-if="
                                      getPublisherCountries &&
                                        getPublisherCountries.countries &&
                                        getPublisherCountries.countries.length >
                                          0 &&
                                        !loadingCountries
                                    "
                                    v-bind:key="index"
                                    v-bind:title="item.name"
                                    class="pb-0 text-body-2 font-weight-regular"
                                    @click="removeCountry(index, item)"
                                    return
                                    obj
                                  >
                                    {{ capitalize(item.name) }}
                                  </v-list-item>
                                  <v-list-item
                                    v-else-if="
                                      !getPublisherCountries.countries.length
                                    "
                                    class="pa-2 text-body-1 font-weight-regular"
                                  >
                                    No countries selected.
                                  </v-list-item>
                                </v-list-item-group>
                              </v-list>
                            </v-container>
                          </v-col>
                        </v-row>
                      </v-row>
                    </v-container>
                  </template>
                </LoadingOverlay>
              </v-row>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!readOnly"
          :disabled="!valid || loadingCountries"
          color="blue darken-1"
          text
          @click="save"
        >
          {{ $t("buttons.general.save") }}</v-btn
        ><v-btn color="blue darken-1" text @click="close">{{
          $t("buttons.general.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import store from "@/store";
import { baseValidation } from "@/validation/baseValidation";
import { validationHelper } from "@/validation/validationHelper";
import { publisherValidation } from "@/validation/publisherValidation";
import LoadingOverlay from "@/components/Helpers/LoadingOverlay.vue";
import IconTooltip from "@/components/Extern/Helper/IconTooltip.vue";

export default {
  name: "PublisherDialog",
  components: { IconTooltip, LoadingOverlay },
  props: ["editedItem", "editedIndex", "gam_network_name"],
  mixins: [baseValidation, validationHelper, publisherValidation],
  data() {
    return {
      show: false,
      readOnly: false,
      valid: true,
      disable_select: false,
      datePicker: false,
      invoiceContacts: [],
      loadingCountries: true,
      search: "",
      schain: "optidigital",
    };
  },
  mounted() {
    store.dispatch("context/fetchAgents");
    store.dispatch("context/fetchInvoiceTypes");
    store.dispatch("context/fetchCurrencies");
    store.dispatch("product/fetchProducts");
    store.dispatch("publishers/fetchSellerTypes");
    if (this.editedItem) {
      this.editedItem.allowExternConfigure ??= false;
    }
  },
  watch: {
    getAllCountries(newVal) {
      this.setAllCountries = newVal;
    },
    getPublisherCountries(newVal) {
      this.countriesList();
      this.setPublisherCountries = newVal;
    },
  },
  computed: {
    orderPublisherCountries() {
      if (this.getPublisherCountries.countries) {
        this.orderCountries(this.getPublisherCountries.countries);
      }
      return this.getPublisherCountries.countries;
    },
    searchCountry() {
      this.orderCountries(this.getAllCountries);
      return this.getAllCountries.filter(country => {
        return country.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    ...mapGetters("publishers", [
      "getLoading",
      "getLoadingPennylane",
      "getPublishers",
      "getGAMNetworkFetchLoading",
      "getPennylaneCustomers",
      "getPennylaneSuppliers",
      "getPennylaneMappingPublisher",
      "getAllCountries",
      "getPublisherCountries",
      "getSellerTypes",
    ]),
    ...mapGetters("context", ["getAgents", "getInvoiceTypes", "getCurrencies"]),
    ...mapGetters("product", ["getProducts"]),
    invoiceContactsList() {
      return this.updateContactList();
    },
  },
  methods: {
    ...mapActions("publishers", [
      "fetchPublishers",
      "updatePublisher",
      "createPublisher",
      "updatePennylaneCustomerMapping",
      "updatePennylaneSupplierMapping",
    ]),
    ...mapMutations("publishers", ["setAllCountries", "setPublisherCountries"]),
    close() {
      this.show = false;
      this.search = "";
    },
    async save() {
      if (this.$refs.form.validate()) {
        const countriesList = this.getPublisherCountries.countries?.map(
          ({ gam_id }) => ({ gam_id })
        );

        const publisher_countries = {
          network_id: this.editedItem.gam_network_id || null,
          publisher_code: this.editedItem.publisher_code,
          countries: countriesList,
        };

        if (this.editedIndex > -1) {
          await this.updatePublisher({
            publisher: {
              ...this.editedItem,
              gam_network_id: this.editedItem.gam_network_id || null,
              allowExternConfigure:
                this.editedItem.allowExternConfigure ?? false,
              default_schain: this.editedItem.default_schain ?? "optidigital",
            },
            publisher_countries,
          });
        } else {
          await this.createPublisher({
            publisher: this.editedItem,
            publisher_countries,
          });
        }

        this.close();
      }
    },
    selectDate() {
      this.datePicker = false;
    },
    updateContactList() {
      this.invoiceContacts = [];
      if (
        this.editedItem.invoice_contacts &&
        this.editedItem.invoice_contacts.length === 0
      ) {
        let obj = {
          id: null,
          name: "",
          email: "",
        };
        this.editedItem.invoice_contacts.push(obj);
        this.invoiceContacts = this.editedItem.invoice_contacts;
      } else {
        this.invoiceContacts = this.editedItem.invoice_contacts;
      }
      return this.invoiceContacts;
    },
    addRow(item) {
      if (item.name && item.email) {
        let obj = {
          id: null,
          name: "",
          email: "",
        };
        this.invoiceContacts.push(obj);
      }
    },
    removeRow(index) {
      this.invoiceContacts.splice(index, 1);
    },
    onSupplierUpdate(val) {
      if (val == null) {
        return;
      }
      const payload = {
        publisher_id: this.editedItem.id,
        pennylane_source_id: val,
      };
      this.updatePennylaneSupplierMapping(payload);
    },
    onCustomerUpdate(val) {
      if (val == null) {
        return;
      }
      const payload = {
        publisher_id: this.editedItem.id,
        pennylane_source_id: val,
      };
      this.updatePennylaneCustomerMapping(payload);
    },
    async deleteAllCountries() {
      await this.getPublisherCountries.countries.forEach(country => {
        this.getAllCountries.push(country);
      });
      this.getPublisherCountries.countries = [];
      await this.getAllCountries;
    },

    async addCountry(item) {
      let objWithIdIndex = this.getAllCountries.findIndex(
        obj => obj.gam_id === item.gam_id
      );
      let newCountry = {
        name: this.formatCountryName(item.name),
        gam_id: item.gam_id,
      };

      await this.getPublisherCountries.countries.push(newCountry);
      await this.getAllCountries.splice(objWithIdIndex, 1);
      this.search = "";
    },

    async removeCountry(index, item) {
      this.getPublisherCountries.countries.splice(index, 1);
      const newCountry = {
        name: item.name.toLowerCase(),
        gam_id: item.gam_id,
      };
      await this.getAllCountries.push(newCountry);
    },

    formatCountryName([firstLetter, ...tail]) {
      return [
        firstLetter.toUpperCase(),
        ...tail.map(w => w.toLowerCase()),
      ].join("");
    },

    orderCountries(countries_list) {
      return countries_list.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      );
    },

    async countriesList() {
      this.loadingCountries = true;
      this.getPublisherCountries.countries ??= [];
      if (
        this.getAllCountries?.length > 0 &&
        this.getPublisherCountries?.countries?.length > 0 &&
        this.getAllCountries.length !==
          this.getPublisherCountries.countries.length
      ) {
        for (let i = this.getAllCountries.length - 1; i >= 0; i--) {
          if (this.getAllCountries !== undefined) {
            for (
              let k = 0;
              k < this.getPublisherCountries?.countries?.length;
              k++
            ) {
              if (
                this.getAllCountries[i] !== undefined &&
                this.getAllCountries[i].gam_id ===
                  this.getPublisherCountries.countries[k].gam_id
              ) {
                await this.getAllCountries.splice(i, 1);
                this.loadingCountries = false;
              }
            }
          }
        }
      }

      if (
        this.getPublisherCountries.countries?.length > 0 &&
        this.getAllCountries.length ===
          this.getPublisherCountries.countries.length
      ) {
        this.getAllCountries.length = 0;
        this.loadingCountries = false;
      }

      if (this.getPublisherCountries.publisher_code === null) {
        return;
      }

      this.loadingCountries = false;
    },

    capitalize(countryName) {
      return countryName.toUpperCase();
    },
  },
};
</script>

<style scoped></style>
