<template>
  <v-container>
    <v-row dense align="center">
      <v-col cols="6">
        <v-row align-content="stretch" justify="center">
          <v-col cols="12">
            <v-card style="height: 40vh;" class="table">
              <v-row>
                <v-card-title class="headline mb-1"
                  >Action Button Table</v-card-title
                >
              </v-row>
              <v-row>
                <v-col>
                  <v-row align="center" justify="center">
                    <v-col class="text-center">
                      <v-btn
                        :loading="loadingPushAllOdasSr"
                        small
                        color="primary"
                        @click="onPushAllOdasSr"
                      >
                        Push ODAS SR - All sites
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col class="text-center">
                      <v-btn
                        :loading="loadingSynchPennylane"
                        small
                        color="primary"
                        @click="onSynchronizePennylane"
                      >
                        Synchronize Pennylane
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-spacer />
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card style="height: 40vh;">
              <v-card-title>Fetch reports</v-card-title>
              <LoadingOverlay :overlay="getLoadingFetchReports">
                <template v-slot:default>
                  <v-menu
                    v-model="datePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="datePickerDate"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="datePickerDate"
                      type="month"
                      @input="datePicker = false"
                    />
                  </v-menu>
                  <v-btn v-on:click="onFetchReports">
                    Fetch Reports
                  </v-btn>
                </template>
              </LoadingOverlay>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="6">
        <v-row align="stretch" justify="center">
          <v-col cols="12">
            <AuditLog />
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row align="center">
      <v-expansion-panels v-model="panel">
        <v-expansion-panel>
          <v-expansion-panel-header>
            Manage users
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-data-table
              outlined
              :headers="user_header"
              :items="getAgents"
              :search="search"
              item-key="id"
              :items-per-page="10"
              :footer-props="{
                'show-first-last-page': true,
                'items-per-page-options': [10, 20, 30, 50, 100],
              }"
              fixed-header
            >
              <template v-slot:item.action="{ item }">
                <v-btn color="primary" text small @click="connect(item)"
                  >Connect as {{ item.email }}
                </v-btn>
              </template>
            </v-data-table>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import AuditLog from "../../components/Admin/AuditLog/AuditLogSmall.vue";
import LoadingOverlay from "@/components/Helpers/LoadingOverlay";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "AdminDashboard",
  components: { LoadingOverlay, AuditLog },
  async mounted() {
    await this.fetchAgents();
  },
  data() {
    return {
      search: "",
      panel: 0,
      homeState: true,
      datePickerDate: new Date().toISOString().substr(0, 7),
      datePicker: false,
      loadingPushAllOdasSr: false,
      loadingSynchPennylane: false,
      user_header: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Role",
          value: "role",
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          filterable: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions("admin", ["fetchReports", "synchronizePennylane"]),
    ...mapActions("siteWebConfig", ["pushAllOdasSr"]),
    ...mapActions("context", ["fetchAgents", "connectAs"]),
    onFetchReports() {
      let payload = {
        year: this.datePickerDate.substr(0, 4),
        month: this.datePickerDate.substr(5, 7),
      };
      this.fetchReports(payload);
    },
    async onPushAllOdasSr() {
      this.loadingPushAllOdasSr = true;
      await this.pushAllOdasSr();
      this.loadingPushAllOdasSr = false;
    },
    async onSynchronizePennylane() {
      this.loadingSynchPennylane = true;
      await this.synchronizePennylane();
      this.loadingSynchPennylane = false;
    },
    async connect({ id }) {
      const response = await this.connectAs({ user_id: id });
      if (response) {
        await this.$router.push({ name: "Home" });
      }
    },
  },
  computed: {
    ...mapGetters("admin", ["getLoadingFetchReports"]),
    ...mapGetters("context", ["getAgents"]),
  },
};
</script>

<style scoped>
.table {
  background: url("./table.jpg");
  background-size: 100% 90%;
  background-repeat: no-repeat;
  background-position: 0px 50px;
}
.v-expansion-panel-header {
  font-weight: bold;
}
</style>
